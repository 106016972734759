@import url(https://fonts.googleapis.com/css?family=DM+Sans&display=swap&css);

body {
  background-color: #f0f2f5 !important;
}

.ant-btn-primary[disabled] {
  opacity: 0.4;
}

.ant-modal-header {
}

.ant-modal-footer {
}

.ant-select {
  color: #43436B;
}

.ant-select-dropdown-menu-item {
  color: #43436B;
}

.ant-table-tbody > tr > td {
  padding: 2px 5px;
  text-align: center;
  font-size: 14px;
}

.ant-table-thead > tr > th {
  font-size: 12px;
}

.ant-table-thead > tr > th {
  padding: 2px 5px;
}

.ant-card-head-title {
  padding: 12px 0 0px;
}
.ant-card-extra {
  padding: 16px 0 0;
}

.react-grid-item {
  &:hover {
    z-index: 1;
  }
}

.DashboardItem_description__2pCkO{margin-bottom:5px;font-size:14px}

